import React, { useState, useRef, useCallback, useEffect } from "react"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import DealerSearch from "./DealerSearch"
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api"
import mapStyles from "../../utilities/mapStyles"
import { useTranslation } from "react-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleXmark,
  faEnvelope,
  faLink,
  faLocationDot,
  faPhone,
  faRotate,
} from "@fortawesome/sharp-light-svg-icons"

const fixDealerLink = (link) => {
  if (link.indexOf("http://") === 0) return link
  else if (link.indexOf("https://") === 0) return link
  else return `http://${link}`
}

const buildMapsDestinationString = (address, postcode, city, lat, lng) => {
  return encodeURIComponent(`${address},${postcode},${city}`)
}

const SelectedDealerMapOverlay = ({ selectedMarker }) => {
  return <DealerDetails marker={selectedMarker} />
}

const DealerDetails = ({ marker }) => {
  const { t } = useTranslation()
  return (
    <>
      <Headline headlineLevel="h5" headline={marker.name} />
      <div className="pb-5 text-sm text-ro-gray-700">
        <p className="pb-1">
          {marker.address} <br />
          {marker.postcode} {marker.city}
        </p>

        {marker.phone && (
          <div>
            <FontAwesomeIcon icon={faPhone} size="1x" />
            &nbsp;
            <a className="hover:text-ro-red" href={`tel:${marker.phone}`}>
              {marker.phone}
            </a>
          </div>
        )}
        {marker.email && (
          <div>
            <FontAwesomeIcon
              icon={faEnvelope}
              size="1x"
              className="inline-block"
            />
            &nbsp;
            <a className="hover:text-ro-red" href={`mailto:${marker.email}`}>
              {marker.email}
            </a>
          </div>
        )}
        {marker.link && (
          <>
            <FontAwesomeIcon icon={faLink} size="1x" className="inline-block" />
            &nbsp;
            <a
              className="hover:text-ro-red"
              target="_blank"
              rel="noreferrer"
              href={fixDealerLink(marker.link)}
            >
              {marker.link}
            </a>
          </>
        )}
        {marker.external_link && marker.external_link !== marker.link && (
          <>
            <FontAwesomeIcon icon={faLink} size="1x" className="inline-block" />
            &nbsp;
            <a
              className="hover:text-ro-red"
              target="_blank"
              rel="noreferrer"
              href={fixDealerLink(marker.external_link)}
            >
              {marker.external_link}
            </a>
          </>
        )}
        <div className="pt-3">
          <FontAwesomeIcon
            icon={faLocationDot}
            size="1x"
            className="inline-block"
          />
          &nbsp;
          <a
            className="hover:text-ro-red"
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.de/maps/dir/?api=1&destination=${buildMapsDestinationString(
              marker.address,
              marker.postcode,
              marker.city,
              marker.latitude,
              marker.longitude
            )}`}
          >
            {t("generic.dealer_map.process_route")}
          </a>
        </div>
      </div>
    </>
  )
}

const DealerListItem = ({ marker, setSelectedMarker, selectedMarker }) => {
  return (
    <div
      className={`border-b px-5 py-1 border-ro-gray-200 last:border-none cursor-pointer${
        marker.id === selectedMarker ? " bg-ro-gray-50" : ""
      }`}
      onClick={() => setSelectedMarker(marker.id)}
    >
      <DealerDetails marker={marker} />
    </div>
  )
}

const containerStyle = {
  display: "block",
  width: "100dvw",
  height: "100dvh",
}

let center = {
  lat: 50.2314798,
  lng: 8.7610036,
}

const options = {
  styles: mapStyles,
}

const DealerMap = ({
  setQueryString,
  queryString,
  setSearchMode,
  searchMode,
  coords,
  error,
  containerWidth,
  containerHeight,
}) => {
  const { t } = useTranslation()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [zoom, setZoom] = useState(
    parseInt(process.env.GATSBY_DEALER_SEARCH_INIT_ZOOM) || 11
  )
  const [formattedAddress, setFormattedAddress] = useState("")
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [visibleMarkers, setVisibleMarkers] = useState(null)
  const [dealerData, setDealerData] = useState(null)
  const [hideError, setHideError] = useState(false)
  let containerStyleSettings = Object.assign({}, containerStyle)

  const loadDealerData = async (country) => {
    await fetch(process.env.GATSBY_DEALER_SEARCH_URL)
      .then((response) => response.json())
      .then((result) => {
        const dealerFiltered = result.locations.filter((dealer) => {
          return dealer.status === 1
        })
        setDealerData({ dealer: dealerFiltered })
      })
  }

  useEffect(() => {
    loadDealerData(process.env.GATSBY_GLOBAL_PATH_PREFIX)

    if (
      process.env.GATSBY_DEALER_SEARCH_CENTER &&
      process.env.GATSBY_DEALER_SEARCH_CENTER.split(",").length === 2
    ) {
      center.lat = parseFloat(
        process.env.GATSBY_DEALER_SEARCH_CENTER.split(",")[0]
      )
      center.lng = parseFloat(
        process.env.GATSBY_DEALER_SEARCH_CENTER.split(",")[1]
      )
    }

    return () => {
      setDealerData(null)
    }
  }, [])

  if (containerWidth) {
    containerStyleSettings.width = containerWidth
  }
  if (containerHeight) {
    containerStyleSettings.height = containerHeight
  }

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  })

  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
    setMapLoaded(true)
  }, [])

  // pan map to search input
  const geoByInput = useCallback(() => {
    let websiteCode = process.env.GATSBY_GLOBAL_PATH_PREFIX
    if (mapLoaded && queryString) {
      let searchObj = { address: decodeURIComponent(queryString) }
      // special case for biased locations in switzerland (e.g. wangen)
      if (websiteCode === "ch" || websiteCode === "at") {
        searchObj.region = websiteCode
      }
      // console.log(searchObj)

      const geocoder = new google.maps.Geocoder()
      geocoder.geocode(searchObj, async (results) => {
        try {
          const result = results[0]
          const address = result.formatted_address
          const lat = await result.geometry.location.lat()
          const lng = await result.geometry.location.lng()
          setFormattedAddress(address)
          panTo({ lat, lng })
          setZoom(12)
        } catch (error) {
          console.error(error)
        }
      })
    }
  }, [mapLoaded, queryString])

  // pan map to geolocation
  const geoByCoords = useCallback(() => {
    if (mapLoaded) {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ location: coords }, async (results) => {
        try {
          const result = results[0]
          const address = result.formatted_address
          setFormattedAddress(address)
          panTo({ lat: coords.lat, lng: coords.lng })
          setZoom(14)
        } catch (error) {
          console.log(error)
        }
      })
    }
  }, [mapLoaded, coords])

  const panTo = ({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng })
  }

  // get all markers in current bounds when map is idle
  let markers = []
  const getVisibleMarkers = () => {
    markers.length > 0 && markers.splice(0, markers.length)
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds()
      dealerData?.dealer.map((marker) => {
        const markerPosition = {
          lat: parseFloat(marker.latitude),
          lng: parseFloat(marker.longitude),
        }
        if (bounds && bounds.contains(markerPosition)) {
          markers.push(marker)
        }
      })
      setVisibleMarkers(markers)
    }
  }

  useEffect(() => {
    if (mapLoaded) {
      if (searchMode === "query") {
        geoByInput()
      } else if (searchMode === "geolocation" && coords) {
        geoByCoords()
      }
    }
  }, [searchMode, mapRef, mapLoaded, coords, geoByInput, geoByCoords])

  useEffect(() => {
    if (mapLoaded) {
      setHideError(false)
    }
  }, [error])

  // if the user blocked tracking the default map will be rendered
  if (loadError) return <div>error</div>
  if (!isLoaded)
    return (
      <div className="py-block block-w-container">
        <FontAwesomeIcon
          icon={faRotate}
          size="1x"
          className="animate-spin transform rotate-180 inline-block -mt-0.5 align-middle"
        />
      </div>
    )
  if (isLoaded)
    return (
      <>
        <section className="w-full">
          {error && hideError === false && (
            <div className="block-w-container">
              <div
                class="bg-red-100 text-sm border border-red-400 text-red-700 px-4 py-3 my-2 relative"
                role="alert"
              >
                <span class="block">{t(error)}</span>
                <button
                  className="absolute z-20 right-2 top-2"
                  onClick={() => setHideError(true)}
                >
                  <FontAwesomeIcon icon={faCircleXmark} size="1x" />
                </button>
              </div>
            </div>
          )}
          <DealerSearch
            activeAddress={formattedAddress}
            setQueryString={setQueryString}
            setSearchMode={setSearchMode}
          />
          <div
            className={`w-full relative ${
              containerStyleSettings.height === "100svh"
                ? " h-screen"
                : "h-full"
            }`}
          >
            <GoogleMap
              mapContainerStyle={containerStyleSettings}
              center={center}
              zoom={zoom}
              options={options}
              onLoad={onMapLoad}
              onIdle={getVisibleMarkers}
            >
              {dealerData?.dealer.map((marker, index) => {
                return (
                  <Marker
                    key={marker.id}
                    position={{
                      lat: parseFloat(marker.latitude),
                      lng: parseFloat(marker.longitude),
                    }}
                    icon={`${process.env.GATSBY_ORIGINAL_HOST}/ro-map-marker.svg`}
                    onClick={() => {
                      setSelectedMarker(marker.id)
                    }}
                  >
                    {marker.id === selectedMarker ? (
                      <InfoWindow
                        onCloseClick={() => {
                          setSelectedMarker(null)
                        }}
                      >
                        <SelectedDealerMapOverlay selectedMarker={marker} />
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )
              })}
            </GoogleMap>
            <div className="absolute top-0 bottom-0 left-0 z-40 invisible px-10 py-20 pointer-events-none md:visible w-96">
              <div className="h-auto max-h-full overflow-y-scroll pointer-events-auto bg-ro-white drop-shadow-md">
                {visibleMarkers &&
                  visibleMarkers.map((marker) => (
                    <DealerListItem
                      key={marker.id}
                      marker={marker}
                      setSelectedMarker={setSelectedMarker}
                      selectedMarker={selectedMarker}
                    />
                  ))}
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default DealerMap
