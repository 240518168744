import React, { useState, useEffect } from "react"
import axios from "axios"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import KlaroConsentInfo from "./KlaroConsentInfo"

import useKlaroConsentable from "../../hooks/useKlaroConsentable"

const KLARO_NEXMART_APP_KEY = "session"

const NexmartPanel = ({ sku, retailersFoundCallback }) => {
  const { usageConsented, appInfo, handleKlaroConsent } = useKlaroConsentable(
    KLARO_NEXMART_APP_KEY
  )

  const [retailers, setRetailers] = useState(null)

  useEffect(() => {
    if (usageConsented) {
      axios
        .get(process.env.NEXMART_API_URL, {
          auth: {
            username: process.env.NEXMART_USERNAME,
            password: process.env.NEXMART_PASSWORD,
          },
          params: {
            services: "online",
            supplier: process.env.NEXMART_SUPPLIER,
            "pid[1]": sku,
          },
        })
        .then((res) => {
          setRetailers(res.data.retailers)
          if (res.data.retailers && res.data.retailers.length > 0) {
            retailersFoundCallback(true)
          } else {
            retailersFoundCallback(false)
          }
        })
    }

    return () => {
      setRetailers(null)
    }
  }, [sku, usageConsented, retailersFoundCallback])

  if (retailers && retailers.length < 1) return null

  return (
    <section className="max-h-[calc(100dvh-3rem)] p-8 overflow-y-auto">
      <div className="flex flex-row flex-wrap -mx-grid">
        {usageConsented === false && appInfo ? (
          <KlaroConsentInfo
            appInfo={appInfo}
            handleKlaroConsent={handleKlaroConsent}
          />
        ) : (
          <>
            {retailers &&
              retailers?.map((retailer, index) => (
                <div className="w-full mb-8 px-grid lg:w-1/3" key={index}>
                  <a
                    href={retailer.products[0].onlineDeeplink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center h-56 p-8 border border-ro-gray-100 hover:border-ro-red"
                  >
                    <Image
                      className="object-contain w-full h-44"
                      image={retailer.logoUrl}
                    />
                  </a>
                </div>
              ))}
          </>
        )}
      </div>
    </section>
  )
}

export default NexmartPanel
